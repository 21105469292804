import './strap-teaser.scss';
import nodeMarquee from 'node-marquee';

class StrapTeaser {
    constructor ($element, options) {
        const defaults = {
            initAttr: 'data-strapteaser'
        };

        this.settings = Object.assign({}, defaults, options);
        this.$strapTeaser = $element;
        this.$strapKeywords = this.$strapTeaser.querySelector(`[${this.settings.initAttr}="keywords"]`);
        this.initialize();
    }

    initialize () {
        this.initStrapAnimation();
    }

    initStrapAnimation () {
        this.marquee = nodeMarquee({
            parent: this.$strapKeywords,
            speed: 0.4
        });
    }
}

export { StrapTeaser };

window.addEventListener('content.loaded', (e) => {
    const eventDetails = e.detail;
    const $context = eventDetails.$context;

    if ($context) {
        const $$strapTeaser = $context.querySelectorAll('[data-strapteaser="root"]');
        for (let i = 0; i < $$strapTeaser.length; i++) {
            const $strapTeaser = $$strapTeaser[i];

            const strapTeaserAPI = new StrapTeaser($strapTeaser);
            $strapTeaser.API = strapTeaserAPI;
        }
    }
});
